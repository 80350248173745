function getVacancies() {
    const componentClassName = 'c-vacancies';

    /* --- Show full description on clicking plus icon --- */
    const readMoreAnchor = $( '.' + componentClassName + '__accordion-btn' );

    readMoreAnchor.on( 'click', function ( e ) {
        e.preventDefault();

        const currentJobPostingFullDescription =
            $( this ).
            parent().
            siblings( '.' + componentClassName + '__posting-content-main' ).
            children( '.' + componentClassName + '__description' );

        const currentJobPostingShortDescription =
            $( this ).
            parent().
            siblings( '.' + componentClassName + '__posting-content-main' ).
            children( '.' + componentClassName + '__short-description' );

        const currentJobPostingAdditionalSubtitle =
            $( this ).
            parent().
            siblings( '.' + componentClassName + '__posting-content-head' ).
            children( '.' + componentClassName + '__subtitle-additional' );

        const currentJobPostingJobApply =
            $( this ).
            parent().
            parent().
            siblings( '.' + componentClassName + '__job-apply' );

        $( this ).toggleClass( componentClassName + '__accordion-btn--sticky' );

        $( this ).children( 'i' ).toggleClass( 'c-vacancies__accordion-btn--plus c-vacancies__accordion-btn--minus' );

        if( $( this ).children( 'i' ).hasClass( 'c-vacancies__accordion-btn--plus' ) ) {
            // $( 'html, body' ).animate( {
            //     scrollTop: $( this ).offset().top - 150
            // }, 400 );
        }

        currentJobPostingShortDescription.slideToggle( 400 );
        currentJobPostingFullDescription.slideToggle( 400 );
        currentJobPostingAdditionalSubtitle.slideToggle( 400 );
        currentJobPostingJobApply.slideToggle( 400 );
    } );
    /* --- End of Show full Description logic --- */
}

export default getVacancies;