/* eslint-disable */

import jQuery from 'jquery';
import '../style.scss';

import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';

import scrollToTop from '../components/objects/scroll-to-top/scroll-to-top';
import searchBar from '../components/objects/search/search-bar/search-bar';
import navMob from '../components/objects/nav/nav-mobile/nav-mobile';
import citySelector from '../components/objects/city-selector/city-selector';
import accordions from '../components/objects/accordion/accordions';
import slider from '../components/blocks-zone-yellow/slider/slider';
import socialShare from '../components/objects/social/social-share/social-share';
import gallery from '../components/blocks-zone-yellow/gallery/gallery';
import tabs from '../components/objects/blog-overview-tabs/blog-overview-tabs';
import filter from '../components/blocks-zone-none-repeater/filter/filter';
import controlCollapsible from '../components/objects/filter/filter-control-checkbox/filter-control-collapsable';
import controlCollapsibleNew from '../components/objects/filter/filter-control/filter-control';
import slideOutProject from '../components/objects/slide-outs/slide-out-project/slide-out-project';
import {
    hamburger,
    hamburgerClose
} from '../components/objects/nav/hamburger/hamburger';

import scrollToAnchor from './custom/scroll/scroll-to-anchor';
import parallaxScroll from './custom/parallax/parallaxscroll';
import {
    setFieldsFilter, setPostFilter
} from './custom/mixitup/mixitup-custom';
import headRoom from './custom/headroom/headroom';
import getVacancies from '../components/blocks-zone-yellow/vacancies/vacancies';
import dropdown from '../components/objects/dropdown/dropdown';
import setMixitFilter from './custom/mixitup/mixitup-collateral';
import actionAboutPages from './custom/pages/about-general';


import './core/core';

/** Responsive Images Polyfill */
import 'picturefill';


// import barba from '@barba/core';


// basic default transition (with no rules and minimal hooks)
// barba.init({
//     transitions: [
//         {
//             leave({ current, next, trigger }) {
//                 // do something with `current.container` for your leave transition
//                 // then return a promise or use `this.async()`
//             },
//             enter({ current, next, trigger }) {
//                 // do something with `next.container` for your enter transition
//                 // then return a promise or use `this.async()`
//             },
//         },
//     ],
// });

// dummy example to illustrate rules and hooks
// barba.init({
//     transitions: [{
//         name: 'dummy-transition',
//
//         // apply only when leaving `[data-barba-namespace="home"]`
//         from: 'home',
//
//         // apply only when transitioning to `[data-barba-namespace="products | contact"]`
//         to: {
//             namespace: [
//                 'products',
//                 'contact'
//             ],
//         },
//
//         // apply only if clicked link contains `.cta`
//         custom: ({ current, next, trigger }) => trigger.classList && trigger.classList.contains('cta'),
//
//         // do leave and enter concurrently
//         sync: true,
//
//         // available hooks…
//         beforeAppear() {},
//         appear() {},
//         afterAppear() {},
//         beforeLeave() {},
//         leave() {},
//         afterLeave() {},
//         beforeEnter() {},
//         enter() {},
//         afterEnter() {}
//
//     }]
// });

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
  /** All pages */
  common,
  /** Home page */
  home
  /** About Us page, note the change from about-us to aboutUs. */
});

// functions below are only run if the component exists (verified through a "data-module" in the html)
// in the ajax container or page. Barba.js instantiates the functions below on container reload as well.

// Place in alphabetical order
const app = {
    actionAboutPages,
    hamburger,
    scrollToTop,
    searchBar,
    gallery,
    citySelector,
    accordions,
    controlCollapsible,
    controlCollapsibleNew,
    filter,
    getVacancies,
    headRoom,
    slider,
    slideOutProject,
    socialShare,
    tabs,
    setFieldsFilter,
    dropdown,
    setMixitFilter,
    setPostFilter
};

let calledModulesNames = [];
app.instantiate = function (elem) {
    const $this = $(elem);
    const module = $this.attr('data-module');

    if (module === undefined) {
        throw 'Module not defined (use data-module="")';
    } else if (module in app) {
        if ($this.attr('data-initialized') === "true") return;
        new app[module](elem);
        $this.attr('data-initialized', true);
    } else {
        throw 'Module \'' + module + '\' not found';
    }
};

jQuery(document).ready(function () {
    routes.loadEvents()

    scrollToAnchor($);
    navMob($);

    // Component functions that should only run if the component exists in the ajax container or page
    $('[data-module]').each(function () {
        if ( $.inArray( $( this ).data( 'module' ), calledModulesNames ) == -1 ) {
            app.instantiate(this);

            calledModulesNames.push( $( this ).data( 'module' ) );
        }
    });
});

jQuery(window).scroll(function () {
    parallaxScroll(jQuery);
});
