/* eslint-disable */
var mixitup = require('mixitup');
import mixitupMultifilter from '../../../js/custom/mixitup/multi';
import mixitupPagination from '../../../js/custom/mixitup/pagination';

function setMixer(mixer) {
	var hash;
	var url;

	if (navigator.userAgent.indexOf('AppleWebKit') != -1) {
		hash = window.location.hash;
	} else {
		url = window.location.href;
		hash = url.substring(url.indexOf('#') + 1);
	}

	if (hash) {
		const filters = hash.replace('#', '').split('_');

		let page = 1;

		if (filters.length > 1 && filters[filters.length - 1].includes('page')) {
			page = filters
				.splice(-1, 1)[0]
				.split('-')
				.splice(-1, 1)[0];
		}

		mixer.multimix({
			filter: '.' + filters.join(', .'),
			paginate: page
		});
	}
}

function hasClass(element, cls) {
	return (' ' + element.className + ' ').indexOf(' ' + cls + ' ') > -1;
}

export default () => {
	mixitup.use(mixitupPagination);
	mixitup.use(mixitupMultifilter);

	let $pillsWrapper = $('.js-pills-wrapper');
	let $pillClone = $('.js-sample-pill')
		.clone()
		.removeClass('hide');

	const mixer = mixitup('.js-content-filter', {
		multifilter: {
			enable: true,
			logicWithinGroup: 'or',
			logicBetweenGroups: 'and'
		},
		pagination: {
			limit: 399 // impose a limit of 12 targets per page
		},
		animation: {
			duration: 300,
			nudge: true,
			reverseOut: false,
			effects: 'fade translateZ(-100px)'
		},
		load: {
			sort: 'name:asc'
		},
		callbacks: {
			onMixEnd: function(state) {
				let finalActiveFilterArray = [];
				let finalActiveFilterString = '';
				const originalActiveFilter = state.activeFilter.selector;

				if( originalActiveFilter.indexOf(',') >= 0 ) {
					originalActiveFilter.trim().split(',').map(originalFilter => {
						originalFilter.trim().split('.').map(filter => {
							if ( filter != '' && $.inArray( filter, finalActiveFilterArray ) < 0 ) {
								finalActiveFilterArray.push(filter);

								finalActiveFilterString += `${filter}_`;
							}
						});
					});
				} else {
					originalActiveFilter.trim().split('.').map(filter => {
						if ( filter != '' && $.inArray( filter, finalActiveFilterArray ) < 0 ) {
							finalActiveFilterArray.push(filter);

							finalActiveFilterString += `${filter}_`;
						}
					});
				}

				finalActiveFilterString = finalActiveFilterString.slice(0, finalActiveFilterString.length - 1);

				const countTotal = state.totalMatching;
				const activePage = state.activePagination.page ? state.activePagination.page : 1;
				const newHash = '#' + finalActiveFilterString + '_page-' + activePage;

				$pillsWrapper.empty();

				let pills = finalActiveFilterString.split('_').map(filter => {
					/* if no filter is set */
					if (filter === 'mix') {
						return;
					}

					let filterLabel = $(`li[data-toggle='.${filter}']`).data('label');
					let $newPillClone = $pillClone.clone();
					$newPillClone.find('span').text(filterLabel);

					/* remove filter hook */
					$newPillClone.find('button').on('click', function(e) {
						$( '[data-toggle=".' + filter + '"]' ).trigger( 'click' );
					});

					return $newPillClone;
				});

				$pillsWrapper.html(pills);

				// $('.mixCount').html(count);
				$('.mixCountTotal').html(countTotal);
			},
			onMixClick: function(state, originalEvent) {
				// mixer.sort('name:asc')
				//reset pagination when using a filter
				if (hasClass(originalEvent.target, 'c-filter-control-multi__btn')) {
					state.activePagination.page = 1;
				}
				// $('html,body').animate({
				//     scrollTop: $(".js-content-filter").offset().top - 100
				// }, 1000);
			}
		}
	});

	setMixer(mixer);

	const aptSliderClass = 'c-photo-gallery-project';

	$( `button[class*=${aptSliderClass}__slider-]` ).on( 'click', function() {
		let btnType = $( this ).data( 'btnType' );
		let currentSlide = $( this ).parent().siblings( `.${aptSliderClass}__slider-wrapper` ).find( `.${aptSliderClass}__slide-wrapper--active` );
		let shownSlide = {};
		let finalSlideOrder = '';

		btnType === 'prev' ? shownSlide = currentSlide.prev() : shownSlide = currentSlide.next();

		btnType === 'prev' ? finalSlideOrder = 'last-child' : finalSlideOrder = 'first-child';

		currentSlide.removeClass( `${aptSliderClass}__slide-wrapper--active` ).fadeOut();

		if ( shownSlide.length ) {
			shownSlide.addClass( `${aptSliderClass}__slide-wrapper--active` ).hide().fadeIn();
		} else {
			let finalSlide = $( this ).parent().siblings( `.${aptSliderClass}__slider-wrapper` ).children( `.${aptSliderClass}__slide-wrapper:${finalSlideOrder}` );

			finalSlide.addClass( `${aptSliderClass}__slide-wrapper--active` ).hide().fadeIn();
		}
	});
};
