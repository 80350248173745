function clickActionOutsideArea( event, componentClass ) {
    if (
        $( event.target ).is( `.${componentClass}` ) === false &&
        $( `.${componentClass}` ).has( event.target ).length === 0
    )
    {
        if ( $( `.${componentClass}__button` ).children( 'i' ).hasClass( 'fa-chevron-up' ) ) {
            $( `.${componentClass}__button` ).trigger( 'click' );
        }
    }
}

export default () => {
    const componentClassName = 'c-about-general';
    const navComponentName   = 'c-nav-extra-wrapper';

    $( `.${componentClassName}__button-txt` ).text( $( '.c-nav--extra .current_page_item .current-menu-item' ).text() );

    $( `.${componentClassName}__button` ).on( 'click', function () {
        $( `.${navComponentName}` ).slideToggle( 400 );

        $( this ).children( 'i' ).toggleClass( 'fa-chevron-down fa-chevron-up' );

        $( window ).resize( function () {
            if ( window.innerWidth >= 768 ) {
                $( `.${navComponentName}` ).css( 'display', 'block' );
            } else if ( window.innerWidth < 768 && $( `.${navComponentName}` ).css( 'display' ) === 'block') {
                if ( $( `.${componentClassName}__button` ).children( 'i' ).hasClass( 'fa-chevron-down' ) ) {
                    $( `.${navComponentName}` ).css( 'display', 'none' );
                }
            }
        } );
    });

    $( document ).on( 'click', function( e ) {
        clickActionOutsideArea( e, componentClassName );
    });

    // This document.click event must fire on iOS Safari
    document.addEventListener('touchstart', function( e ) {
        clickActionOutsideArea( e, componentClassName );
    });
};