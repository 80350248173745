/*
 * Search Toggle
 *
 * runs the functionality of clicking a search icon and a search bar appears
 */

function searchBar() {

    // Search Toggle
    $('.js-header-search-toggle').on('click', function (e) {
        e.stopPropagation()
        $(".c-search-bar").toggleClass('c-search-bar--active');
        $("body").toggleClass('h-scroll-lock');

        setTimeout(function () {
            $('.c-search-bar__search-field').focus();
        }, 600);
    });

    // remove search bar on clicking other elements
    $("body").click(function(){
        $(".c-search-bar").removeClass('c-search-bar--active');
        $("body").removeClass('h-scroll-lock');
        $('.c-search-bar__search-field').blur();
    })
    // preventing from remove on bar clicking
    $(".c-search-bar").click(function(e){
        e.stopPropagation()
    })
}

export default searchBar;
