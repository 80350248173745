// import "lightGallery";

function gallery() {

    /*$('.js-animated-thumbnails').lightGallery({
        selector: '.c-gallery__img',
        thumbnail: true,
        animateThumb: true,
        showThumbByDefault: false,
        mode: 'lg-slide'
    });*/
}

export default gallery;
