function accordions() {
    $('.js-accordion').each(function () {
        $(this).click(function(e) {
            e.preventDefault();
            $(this).parent().toggleClass('is-active');
            $(this).toggleClass('active').next().slideToggle('fast');
        });
    });
}

export default accordions;
