/*
 * MixItUp Multi Filtering Plugin
 *
 */
let mixitup = require('mixitup');
let mixItUpMultiFilterExtension = require('../../custom/mixitup/mixitup-multifilter');

export function setFieldsFilter() {
    mixitup.use(mixItUpMultiFilterExtension);

    var mixer = mixitup('.c-vacancies__postings-wrapper', {
        multifilter: {
            enable: true, // enable the multifilter extension for the mixer
            parseOn: 'submit'
        },
        callbacks: {
            onMixStart: function( state, futureState ) {
                $( ".c-vacancies__posting-not-found" ).removeClass("c-vacancies__posting-not-found--show");
            },
            onMixFail: function( state ) {
                $( ".c-vacancies__posting-not-found" ).addClass("c-vacancies__posting-not-found--show");
            }
        }
    });
}

export function setPostFilter() {
    mixitup.use(mixItUpMultiFilterExtension);
    const containerClass = '.js-mixit-wrapper'
    var mixer = mixitup(containerClass, {
        multifilter: {
            enable: true,
        },
        callbacks: {
            onMixStart: function( state, futureState ) {
            },
            onMixFail: function( state ) {
            }
        }
    });
}

// /* eslint-disable */
// function filterCareers() {
//     mixitup.use(mixItUpMultiFilterExtension);

//     let mixer = mixitup('.c-career-filter__main-container', {
//         multifilter: {
//             enable: true // enable the multifilter extension for the mixer
//         },
//         animation: {
//             duration: 500
//         },
//         callbacks: {
//             onMixStart: function( state, futureState ) {
//                 $( ".c-career-filter__no-results" ).removeClass("c-career-filter__no-results--show");
//             },
//             onMixFail: function( state ) {
//                 $( ".c-career-filter__no-results" ).addClass("c-career-filter__no-results--show");
//             }
//         }
//     });

//     $( '.c-career-filter__no-results-btn' ).on( 'click', function () {
//        $( '.c-career-filter__reset-btn' ).trigger( 'click' );
//     });

//     $( '.c-career-filter__selector-item-wrapper' ).on( 'click', function () {
//         $( this ).toggleClass( 'c-career-filter__selector-item-wrapper--active' );
//     } );

//     $( 'body' ).on( 'click', function ( e ) {
//         if ( e.target.nodeName != 'SELECT' ) {
//             $( '.c-career-filter__selector-item-wrapper' ).removeClass( 'c-career-filter__selector-item-wrapper--active' );
//         }
//     } );

//     let filterContainer = $( '.c-career-filter__head' );
//     let parentLContainer = $( '.c-career-filter__head' ).parent().parent();

//     $( window ).on( 'scroll', function () {
//         if ( $( '.c-career-filter__selector-item-wrapper' ).hasClass( 'c-career-filter__selector-item-wrapper--active' ) ) {
//             $( '.c-career-filter__selector-item-wrapper' ).removeClass( 'c-career-filter__selector-item-wrapper--active' );
//         }

//         if ( ( $(window).scrollTop() + 50 ) == filterContainer.offset().top && !parentLContainer.hasClass( 'l-container--filter-sticky' ) ) {
//             parentLContainer.addClass( 'l-container--filter-sticky' );
//         }

//         if ( ( $(window).scrollTop() + 50 ) < filterContainer.offset().top && parentLContainer.hasClass( 'l-container--filter-sticky' ) ) {
//             parentLContainer.removeClass( 'l-container--filter-sticky' );
//         }
//     } );
// }

// export default filterCareers;
