/*
 * MixItUp Multi Filtering Plugin
 *
 */
let mixitup = require('mixitup');
let mixItUpMultiFilterExtension = require('./mixitup-multifilter');

function setMixitFilter() {
    mixitup.use(mixItUpMultiFilterExtension);
    // const containerClass = '.c-vacancies__postings-wrapper'
    const containerClass = '.js-mix-container'
    var mixer = mixitup(containerClass, {
        multifilter: {
            enable: true,
        },
        callbacks: {
            onMixStart: function( state, futureState ) {
            },
            onMixFail: function( state ) {
            }
        }
    });
    $(".js-filter-controls-reset").click(function(){
        $(this).find(["data-filter"]).removeClass("mixitup-control-active")
        $(this).closest(".c-filter-control").find(".c-filter-control__dropdown").each(function(){
            var current = $(this).find("[data-filter='.mix']")
            current.addClass("mixitup-control-active")
            current.parent().siblings().removeClass("c-dropdown__item--active")
            current.parent().addClass("c-dropdown__item--active")
            $(this).find(".c-dropdown__current-value").text(current.text())
        })
    })
}
export default setMixitFilter;
