/*
 * Headroom
 *
 * Calling the Headroom.js object
 */
/* eslint-disable */
var Headroom = require('headroom.js');

function headRoom() {
    $(function () {
        if ( !document.cookie.split(';').some( (item) => item.includes( 'top-header-seen=yes' ) ) ) {
            $( '.l-header__top-section' ).addClass( 'l-header__top-section--show' );
        }
    });

    // grab an element
    var header = document.querySelector("#header");
    var btn_page = ".c-button-page";
    let activeClass = 'c-button-page--active';

    // construct an instance of Headroom, passing the element
    var headroom = new Headroom(header, {
        offset: 40,
        tolerance: {
            up: 15,
            down: 25,
        },
        classes: {
            // when element is initialised
            initial: "l-headroom",
            // when scrolling up
            pinned: "l-headroom--pinned",
            // when scrolling down
            unpinned: "l-headroom--unpinned",
            // when above offset
            top: "l-headroom--top",
            // when below offset
            notTop: "l-headroom--not-top",
            // when at bottom of scoll area
            bottom: "l-headroom--bottom",
            // when not at bottom of scroll area
            notBottom: "l-headroom--not-bottom",
        },
        onNotTop: function () {
            $(btn_page).addClass(activeClass);
        },
        onTop: function () {
            $(btn_page).removeClass(activeClass);
        }
    });

    // initialise
    headroom.init();

    $( '.l-header__top-section-close-btn' ).on( 'click', function () {
        let siteDomainName = $( this ).data( "domainName" ),
            expiredDateData = $( this ).data( "expiredDate" );

        let expiredDateObject = new Date( expiredDateData ),
            expiredDateString = expiredDateObject.toUTCString();

        $( '.l-header__top-section' ).removeClass( 'l-header__top-section--show' );

        document.cookie = `top-header-seen=yes; expires=${expiredDateString}; path=/; domain=${siteDomainName}`;
    } );
}

export default headRoom;
